import { MapSource } from '../../types/MapSource'
import { MapLayer } from '../../types/MapLayer'
import { Layer, Source } from 'react-map-gl'
import { useState, useEffect, useContext } from 'react'
import settings from '../../settings.json'
import { FillLayer } from 'mapbox-gl';
import { RefContext } from '../../context/RefContext';

const { v4 } = require('uuid')

const HucList = (props: any) => {

    const { data, fillColor, outlineColor, opacity } = props

    const [huc8Filter, setHuc8Filter] = useState([])
    const [features, setFeatures] = useState()
    const [huc12Filter, setHuc12Filter] = useState([
        'in',
        'HUC12',
    ])

    // @ts-ignore
    const { currentRef } = useContext(RefContext)

    const buildMap = () => {
        let huc8s = data.map((huc8: any) => huc8.huc8)
        setHuc8Filter(huc8s)
        let outer = data.map((huc8: any) => {
            return huc8.huc12s.map((huc12: any) => huc12.huc_12_code)
        })
        setHuc12Filter((oldState) =>
            [...oldState].concat(outer.flat())
        )
    }


    // @ts-ignore
    const mapInstance = currentRef && currentRef?.current

    // useEffect(() => mapInstance && mapInstance.on('click', (event: any) => {
    //     const layerObject = mapInstance.queryRenderedFeatures(event.point);
    //     setFeatures(layerObject)
    // }), [mapInstance])

    useEffect(() => {
        if (data) {
            buildMap()
        }
    }, [])


    const huc12sLayerPaint = {
        'fill-opacity': opacity,
        'fill-color': `${fillColor}`,
        'fill-outline-color': `${outlineColor}`,
    };

    const huc12sLayer = new MapLayer(
        'True Elements - HUC 12s',
        'fill',
        huc12sLayerPaint,
        8,
    );

    const huc12sSource = new MapSource(
        'vector',
        settings.tileSetURLs.HUC12,
        huc12sLayer as FillLayer,
        props.switch ? huc12Filter : ['all']
    );

    const huc8sLayerPaint = {
        'fill-opacity': opacity,
        'fill-color': `${fillColor}`,
        'fill-outline-color': `${outlineColor}`,
    };

    const huc8slayer = new MapLayer(
        'True Elements - huc8s',
        'fill',
        huc8sLayerPaint,
        0,
    );

    const huc8sSource = new MapSource(
        'vector',
        settings.tileSetURLs.HUC8,
        huc8slayer as FillLayer,
        props.switch ? huc8Filter : ['all'],
        huc12sSource
    );


    const drillDownMapSources = (mapSource: MapSource, innerSources: any[]) => {
        let filter = mapSource.source?.layerFilter;

        if (mapSource.source) {
            const sourceElement = (
                <Source
                    key={v4()}
                    id={mapSource.source.id}
                    type={mapSource.source.type as 'vector'}
                    url={mapSource.source.tilesetURLs}
                >
                    <Layer {...mapSource.source.layer} {...{ filter }} beforeId={'aerialway'} />
                </Source>
            );
            innerSources.push(sourceElement);
            drillDownMapSources(mapSource.source, innerSources);
        } else {
            return null;
        }
        return innerSources;
    };

    const renderSources = () => {
        return (
            <Source
                key={v4()}
                id={huc8sSource.id}
                type={huc8sSource.type as 'vector'}
                url={huc8sSource.tilesetURLs}
            >
                {drillDownMapSources(huc8sSource, [])?.map((x) => x)}
                <Layer
                    {...huc8sSource.layer}
                    filter={huc8sSource.layerFilter}
                    beforeId="epo_mask"
                />
            </Source>
        )
    }
    return (
        <>
            {renderSources()}
        </>
    )
}

export default HucList