import OutflowLayer from "./OutflowLayer";
import settings from '../../settings.json'
import VectorTileLayer from "./VectorTileLayer"
import MaskLayer from "./MaskLayer";
import HucList from './HucList'


const RenderLayers = (props: any) => {

    const { global, mapRef } = props

    return (
        <>
            {/* {global.nhdSelected
                | global.hucsSelected
                | global.outflowsSelected
                | global.principalAquifersSelected
                | global.domesticWellsSelected
                | global.zipSelected
                | global.ohioOutflows && (
                    
                )
            } */}
            <MaskLayer
                id="epo_mask"
                fillColor={'black'}
                opacity={0.7}
                outlineColor={'black'}
                tileSet={settings.tileSetURLs.epoMask}
                sourceLayer={'east_palestine_mask_v1'}
            />
            {global.ohioOutflows && (
                <VectorTileLayer
                    id="ohio_outflow_dec"
                    fillColor={[
                        "to-string",
                        ["get", "description"]
                    ]}
                    opacity={0.5}
                    outlineColor={'black'}
                    tileSet={settings.tileSetURLs.OhioOutflows}
                    sourceLayer={'Ohio_East_Palestine-b9h33i'}
                />
            )}
            {global.ohioOutflows && (
                <VectorTileLayer
                    id="ohio_outflow_outflow"
                    fillColor={[
                        "match",
                        ["get", "OUTHUC12"],
                        [
                            "050301010606",
                            "050301010608",
                            "050301010610",
                            "050301011103",
                            "050301011106",
                            "050301011109",
                            "050301061202",
                            "050301061204",
                            "050301061205",
                            "050301061206",
                            "050301061207",
                            "050301061208",
                            "050302011001",
                            "050302011004",
                            "050301010607",
                            "050302011005",
                            "050302011006",
                            "050302011007",
                            "050302011009",
                            "050302011010",
                            "050302020102",
                            "050302020106",
                            "050302020404",
                            "050302020802",
                            "050302020803",
                            "050302020804",
                            "050302020805",
                            "050302020904",
                            "050901010103",
                            "050901010704",
                            "050901010709",
                            "050901011007",
                            "050901030101",
                            "050901030105",
                            "050901030107",
                            "050901030606",
                            "050902010204",
                            "050902010207",
                            "050902010210",
                            "050902010601",
                            "050902010605",
                            "050902010804",
                            "050902011102",
                            "050902011106",
                            "050902011107",
                            "050902011203",
                            "050902011208",
                            "050902030201",
                            "050902030202",
                            "050902030204",
                            "050902030802",
                            "050902030804",
                            "050902030808",
                            "050902031004",
                            "050902031006",
                            "050902031009",
                            "050902031010",
                            "051401010301",
                            "051401010302",
                            "051401010304",
                            "051401010305",
                            "051401010602",
                            "051401010603",
                            "051401010604",
                            "051401010605",
                            "051401010904",
                            "051401040102",
                            "051401040107",
                            "051401041001",
                            "051401041002",
                            "051401041004",
                            "051401041403",
                            "051401041405",
                            "051402010204",
                            "051402010205",
                            "051402010703",
                            "051402010805",
                            "051402011202",
                            "051402011203",
                            "051402011204",
                            "051402020401",
                            "051402020602",
                            "051402020605",
                            "051402020705",
                            "051402030103",
                            "051402030303",
                            "051402030306",
                            "051402030504",
                            "051402030704",
                            "051402030705",
                            "051402030904",
                            "051402030905",
                            "051402060102",
                            "051402060202",
                            "051402060701",
                            "051402060702",
                            "051402060703",
                            "051402060704",
                            "080101000103",
                            "080101000201",
                            "080101000202",
                            "080101000301",
                            "080101000401",
                            "080101000502",
                            "080101000503",
                            "080101000602",
                            "080101000604",
                            "080101000605",
                            "080101000701",
                            "080101000702",
                            "080101000703",
                            "080101000704",
                            "080201000102",
                            "080201000200",
                            "080201000302",
                            "080301000100",
                            "080301000200",
                            "080301000300",
                            "080601000200",
                            "080601000302",
                            "080601000502",
                            "080601000600",
                            "080701000101",
                            "080701000103",
                            "080701000104",
                            "080701000105",
                            "080901000101",
                            "080901000102",
                            "080901000207",
                            "080901000300"
                        ],
                        "hsl(51, 91%, 59%)",
                        "hsla(0, 0%, 0%, 0)"
                    ]}
                    opacity={0.5}
                    outlineColor={'black'}
                    tileSet={settings.tileSetURLs.OhioOutflows}
                    sourceLayer={'Ohio_East_Palestine-b9h33i'}
                />
            )}

            {global.ohioOutflows && (
                <VectorTileLayer
                    id="ohio_outflow_outflow_huc12"
                    fillColor={[
                        "match",
                        ["get", "HUC12"],
                        [
                            "050301010608",
                            "050301010610",
                            "050301011103",
                            "050301011106",
                            "050301011109",
                            "050301061202",
                            "050301061204",
                            "050301061205",
                            "050301061206",
                            "050301061207",
                            "050301061208",
                            "050302011001",
                            "050302011004",
                            "050301010607",
                            "050302011005",
                            "050302011006",
                            "050302011007",
                            "050302011009",
                            "050302011010",
                            "050302020102",
                            "050302020106",
                            "050302020404",
                            "050302020802",
                            "050302020803",
                            "050302020804",
                            "050302020805",
                            "050302020904",
                            "050901010103",
                            "050901010704",
                            "050901010709",
                            "050901011007",
                            "050901030101",
                            "050901030105",
                            "050901030107",
                            "050901030606",
                            "050902010204",
                            "050902010207",
                            "050902010210",
                            "050902010601",
                            "050902010605",
                            "050902010804",
                            "050902011102",
                            "050902011106",
                            "050902011107",
                            "050902011203",
                            "050902011208",
                            "050902030201",
                            "050902030202",
                            "050902030204",
                            "050902030802",
                            "050902030804",
                            "050902030808",
                            "050902031004",
                            "050902031006",
                            "050902031009",
                            "050902031010",
                            "051401010301",
                            "051401010302",
                            "051401010304",
                            "051401010305",
                            "051401010602",
                            "051401010603",
                            "051401010604",
                            "051401010605",
                            "051401010904",
                            "051401040102",
                            "051401040107",
                            "051401041001",
                            "051401041002",
                            "051401041004",
                            "051401041403",
                            "051401041405",
                            "051402010204",
                            "051402010205",
                            "051402010703",
                            "051402010805",
                            "051402011202",
                            "051402011203",
                            "051402011204",
                            "051402020401",
                            "051402020602",
                            "051402020605",
                            "051402020705",
                            "051402030103",
                            "051402030303",
                            "051402030306",
                            "051402030504",
                            "051402030704",
                            "051402030705",
                            "051402030904",
                            "051402030905",
                            "051402060102",
                            "051402060202",
                            "051402060701",
                            "051402060702",
                            "051402060703",
                            "051402060704",
                            "080101000103",
                            "080101000201",
                            "080101000202",
                            "080101000301",
                            "080101000401",
                            "080101000502",
                            "080101000503",
                            "080101000602",
                            "080101000604",
                            "080101000605",
                            "080101000701",
                            "080101000702",
                            "080101000703",
                            "080101000704",
                            "080201000102",
                            "080201000200",
                            "080201000302",
                            "080301000100",
                            "080301000200",
                            "080301000300",
                            "080601000200",
                            "080601000302",
                            "080601000502",
                            "080601000600",
                            "080701000101",
                            "080701000103",
                            "080701000104",
                            "080701000105",
                            "080901000101",
                            "080901000102",
                            "080901000207",
                            "080901000300"
                        ],
                        "hsla(182, 93%, 67%, 0.85)",
                        "hsla(119, 93%, 67%, 0)"
                    ]}
                    opacity={0.5}
                    outlineColor={'black'}
                    tileSet={settings.tileSetURLs.OhioOutflows}
                    sourceLayer={'Ohio_East_Palestine-b9h33i'}
                />
            )}

            {global.ohioOutflows && (
                <VectorTileLayer
                    id="ohio_outflow_outflow_huc8s"
                    fillColor={[
                        "match",
                        ["get", "HUC8"],
                        [
                            "08010100",
                            "08070100",
                            "08030100",
                            "08060100",
                            "08090100",
                            "08020100"
                        ],
                        "hsla(97, 41%, 45%, 0.5)",
                        "hsla(298, 88%, 40%, 0)"
                    ]}
                    opacity={0.5}
                    outlineColor={'black'}
                    tileSet={settings.tileSetURLs.OhioOutflows}
                    sourceLayer={'Ohio_East_Palestine-b9h33i'}
                />
            )}

            {global.ohioOutflows && (
                <VectorTileLayer
                    id="ohio_outflow_outflow_huc8-2"
                    fillColor={[
                        "match",
                        ["get", "HUC8"],
                        [
                            "05030106",
                            "05030101",
                            "05030202",
                            "05030201"
                        ],
                        "hsla(0, 87%, 34%, 0.5)",
                        "hsla(298, 88%, 40%, 0)"
                    ]}
                    opacity={0.5}
                    outlineColor={'black'}
                    tileSet={settings.tileSetURLs.OhioOutflows}
                    sourceLayer={'Ohio_East_Palestine-b9h33i'}
                />
            )}

            {global.ohioOutflows && (
                <VectorTileLayer
                    id="ohio_outflow_outflow3"
                    fillColor={[
                        "match",
                        ["get", "HUC8"],
                        [
                            "08010100",
                            "05140101",
                            "05140202",
                            "05030106",
                            "05090101",
                            "08070100",
                            "05090103",
                            "05140206",
                            "05140104",
                            "05030101",
                            "08030100",
                            "05090201",
                            "05140203",
                            "08060100",
                            "05030202",
                            "05090203",
                            "08090100",
                            "08020100",
                            "05030201",
                            "05140201"
                        ],
                        "hsla(23, 90%, 44%, 0.5)",
                        "hsla(298, 88%, 40%, 0)"
                    ]}
                    opacity={0.5}
                    outlineColor={'black'}
                    tileSet={settings.tileSetURLs.OhioOutflows}
                    sourceLayer={'Ohio_East_Palestine-b9h33i'}
                />
            )}


            {global.outflowsSelected && (
                <OutflowLayer />
            )}

            {global.nhdSelected && (
                <>
                    <VectorTileLayer
                        id="nhd_one"
                        fillColor={'#00BCD4'}
                        opacity={0.5}
                        outlineColor={'#00BCD4'}
                        tileSet={settings.tileSetURLs.nhd_flowline_one}
                        sourceLayer={'nhd_hr_nhdflowline_one'}
                    />
                    <VectorTileLayer
                        id="nhd_two"
                        fillColor={'#00BCD4'}
                        opacity={0.5}
                        outlineColor={'#00BCD4'}
                        tileSet={settings.tileSetURLs.nhd_flowline_two}
                        sourceLayer={'nhd_hr_nhdflowline_two'}
                    />
                    <VectorTileLayer
                        id="nhd_waterbody"
                        fillColor={'#00BCD4'}
                        opacity={0.5}
                        outlineColor={'#00BCD4'}
                        tileSet={settings.tileSetURLs.nhd_waterbody_tiles}
                        sourceLayer={'nhd_hr_nhdwaterbody'}
                    />
                    <VectorTileLayer
                        id="nhd_area_tiles"
                        fillColor={'#00BCD4'}
                        opacity={0.5}
                        outlineColor={'#00BCD4'}
                        tileSet={settings.tileSetURLs.nhd_area_tiles}
                        sourceLayer={'nhd_hr_nhdarea'}
                    />
                </>
            )}


            {global.hucsSelected && (
                <>
                    <HucList
                        fillColor={'transparent'}
                        outlineColor={'white'}
                        opacity={1}
                        switch={false}
                        mapRef={mapRef}
                    />
                </>
            )}
            {global.droughtMonitoringSelected && (
                <VectorTileLayer
                    id="drought"
                    fillColor={[
                        'match',
                        ['get', 'DM'],
                        0, '#f4fb18',
                        1, '#f99010',
                        2, '#ea631f',
                        3, '#fa3f19',
                        4, '#ab0707',
                    /* other */ 'transparent'
                    ]}
                    opacity={0.4}
                    outlineColor={'#8B8000'}
                    tileSet={settings.tileSetURLs.US_Daily_Drought_Monitoring}
                    sourceLayer={settings.tileSetURLs.droughtSource}
                />
            )}

            {global.principalAquifersSelected && (
                <VectorTileLayer

                    id="aquifers"
                    fillColor={[
                        'match',
                        ['get', 'ROCK_NAME'],
                        // 0, 'transparent',
                        'Carbonate-rock aquifers', '#8a658b',
                        'Igneous and metamorphic-rock aquifers', '#efa3a3',
                        'Sandstone and carbonate-rock aquifers', '#f1c065',
                        'Sandstone aquifers', '#633b2c',
                        'Semiconsolidated sand aquifers', '#2d2843',
                        'Unconsolidated sand and gravel aquifers', '#292154',
                  /* other */ 'transparent'
                    ]}
                    opacity={0.5}
                    outlineColor={'transparent'}
                    tileSet={settings.tileSetURLs.Principal_Aquifer_Map}
                    sourceLayer={settings.tileSetURLs.aquiferSource}
                />
            )}
            {global.domesticWellsSelected && (
                // thing1
                <VectorTileLayer
                    id="domestic-wells"
                    fillColor={[
                        'match',
                        ['get', 'z'],
                        // 0, 'transparent',
                        6, '#254153',
                        5, '#0b61bf',
                        4, '#98c3f0',
                        3, '#ddeded',
                        2, '#4fc32c',
                        1, '#2c8f29',
                /* other */ 'transparent'
                    ]}
                    opacity={0.5}
                    outlineColor={'transparent'}
                    tileSet={settings.tileSetURLs.Domestic_Well_by_Population}
                    sourceLayer={settings.tileSetURLs.domesticWellsSource}
                />
            )}
            {global.zipSelected && (
                <VectorTileLayer
                    id="zip-codes"
                    fillColor={'transparent'}
                    opacity={1}
                    outlineColor={'orange'}
                    tileSet={settings.tileSetURLs.ZipCodeMap}
                    sourceLayer={settings.tileSetURLs.ZipCodeSource}
                />
            )}

        </>
    )
}

export default RenderLayers