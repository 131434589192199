import { useState } from 'react'
import { Dropdown, Row, Col, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faGlobe, faGlobeAmericas, faMap } from '@fortawesome/free-solid-svg-icons'
import hucImg from '../../assets/img/huc-layer.svg'
import gwNitrateAssessmentImg from '../../assets/img/gw-nitrate-assessment.svg'
import outflowsImg from '../../assets/img/outflows-layer.svg'
import nhdImg from '../../assets/img/nhd-layer.svg'

const zipIcon = require('../../assets/img/zipCodesv2.png') 
const domesticWells = require('../../assets/img/popWell2.png') 
const PrincipleAquifers = require('../../assets/img/PA.png') 
const checkCircleImg = require('../../assets/img/check-circle.png') 
const EPLayer = require('../../assets/img/EPLayer.png')



const Layers = ({ global }: any) => {

   const [selected, setSelected] = useState(false)

   const {
      nhdSelected, setNhdSelected,
      hucsSelected, setHucsSelected,
      outflowsSelected, setOutflowsSelected,
      principalAquifersSelected, setPrincipalAquifersSelected,
      domesticWellsSelected, setDomesticWellsSelected,
      zipSelected, setZipSelected,
      ohioOutflows, setOhioOutflows
   } = global
   
   return (
      <Dropdown className='layers-sidebar'>
         <Dropdown.Toggle id="dropdown-basic">
            <FontAwesomeIcon icon={faLayerGroup} className="mr-2" />
            Layers
         </Dropdown.Toggle>
         <Dropdown.Menu>
            <Accordion>
               <Accordion.Item eventKey="0">
                  <Accordion.Header className="te-map-legend-accordion-header"><div className='title'><FontAwesomeIcon icon={faGlobe} className="mr-2" />Layers</div></Accordion.Header>
                  <Accordion.Body style={{ padding: '0.5rem', paddingTop: '8px' }}>
                     <Row>
                        <Col>
                           <LayerItem
                              img={nhdImg}
                              name='Lakes, Rivers, and Streams'
                              selected={nhdSelected}
                              setSelected={setNhdSelected}
                           />
                        </Col>
                        <Col>
                           <LayerItem
                              img={hucImg}
                              name='Watersheds (HUCs)'
                              selected={hucsSelected}
                              setSelected={setHucsSelected}
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col>
                           <LayerItem
                              img={outflowsImg}
                              name='Outflows'
                              selected={outflowsSelected}
                              setSelected={setOutflowsSelected}
                           />
                        </Col>
                        <Col>
                           <LayerItem
                              img={PrincipleAquifers}
                              name='Principal Aquifers'
                              selected={principalAquifersSelected}
                              setSelected={setPrincipalAquifersSelected}
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col>
                           <LayerItem
                              img={domesticWells}
                              name='Domestic Well locations'
                              selected={domesticWellsSelected}
                              setSelected={setDomesticWellsSelected}
                           />
                        </Col>
                        <Col>
                           <LayerItem
                              img={zipIcon}
                              name='Zip Codes'
                              selected={zipSelected}
                              setSelected={setZipSelected}
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col>
                           <LayerItem
                              img={EPLayer}
                              name='Ohio Outflows'
                              selected={ohioOutflows}
                              setSelected={setOhioOutflows}
                           />
                        </Col>
                     </Row>
                  </Accordion.Body>
               </Accordion.Item>
            </Accordion>
         </Dropdown.Menu>
      </Dropdown>
   );
}

export default Layers

function LayerItem(props: any) {
   const { img, name, selected, setSelected, disabled } = props;
 

   const onClick = () => {
      if (selected) {
         setSelected(false);
      }
      else {
         setSelected(true);
      }
   };

   return (
      <div
         style={{ pointerEvents: disabled ? 'none' : 'auto' }}
         className={'thumbnail'}
      >
         <img className={selected ? 'layer selected' : 'layer'} src={img} onClick={onClick} />
         <img className='check' src={checkCircleImg} />
         {disabled && <img className='check' src={checkCircleImg} />}
         <div className='description'>{name}</div>
      </div>
   );
}