import { useState, useEffect } from 'react'
import { parse } from "papaparse";

const CellPopup = (props: any) => {
    const { chem, cas } = props

    const [windowOpen, setWindowOpen] = useState(false)
    const [popupData, setPopupData] = useState()
    const [casInfo, setCasInfo] = useState()
    const [contaminateCatalog, setContaminateCatalog] = useState<any>()

    const format = () => {
        const data = cas.map((item: any) => item.body[0])
        setCasInfo(data)
    }

    const findContaminate = (input: any) => {
        const chem = contaminateCatalog && contaminateCatalog.find(
            (chem: any) => chem['Common Name'].toLowerCase().split(' ')[0] === input.toLowerCase().split(' ')[0]
        )
        if (chem) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        const mastercontaminantlist = "/mastercontaminantlist.csv";
        fetch(mastercontaminantlist)
            .then((response) => response.text())
            .then((responseText) => {
                const data = parse(responseText, {
                    header: true,
                }).data;
                setContaminateCatalog(data);
            });
    }, []);

    useEffect(() => {
        format()
    }, [cas])

    const handleHover = (input: string) => {
        // @ts-ignore
        const data = casInfo.filter((item: any) => item.Contaminant === input)
        setPopupData(data[0])
    }

    const slugify = (str: string) => str
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
        .replace(/-tthms/g, '-thm')
        .replace(/total-/g, '')
        .replace(/-as-([^\s]+)/g, '')

    return (
        <td
            className="table-data chemical"
            onMouseEnter={() => {
                setWindowOpen(true)
                handleHover(chem)
            }}
            onMouseLeave={() => {
                setWindowOpen(false)
            }}
        >
            {chem}
            {windowOpen && (
                <div className="cas-info">
                    <div className="cas-container">
                        <strong>Epa Health Effects Description:</strong> <br />
                        {
                            popupData ? (
                                <>
                                    {popupData['Epa Health Effects Description']}
                                    <br />
                                    {findContaminate(chem.replace(/total/ig, '').trim()) && (
                                        <a href={`https://trueelements.com/glossary/${slugify(chem)}`} target={'_blank'}>
                                            More info
                                        </a>
                                    )}
                                </>
                            ) : (
                                <>
                                    {findContaminate(chem.replace(/total/ig, '').trim()) ? (
                                        <a href={`https://trueelements.com/glossary/${slugify(chem)}`} target={'_blank'}>
                                            More info
                                        </a>
                                    ): 'Data currently unavailable'}
                                </>
                            )
                        }
                    </div>
                </div>
            )}
        </td>
    )
}

export default CellPopup