import {
    Navbar,
    Row,
    Col,
} from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

const TELogo = require('../../assets/img/logo.png')

const NavigationBar = ({ viewport, setViewport }: any) => {

    const navigate = useNavigate()

    return (
        <Navbar className="col-12 nav-bar position-relative">
            <Row className="d-flex w-100">
                <Col className="col-12 d-flex logo-wrapper">
                    <Col className='col-5 mt-1'>
                        <Navbar.Brand style={{ cursor: 'pointer' }} onClick={() => navigate('/')} ><img width="150px" src={TELogo} /></Navbar.Brand>
                    </Col>
                    <Col className='col-7 d-flex justify-content-end'>
                        <Navbar.Brand style={{ color: 'white', fontWeight: 'bold' }} onClick={() => navigate('/')} >Ohio Outflows</Navbar.Brand>
                    </Col>
                </Col>
            </Row>
        </Navbar>
    );
}

export default NavigationBar