import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export function qualityReferences(input: string) {

    if (input === "outflows") {
        return (
            <div className='quality-references'>
                <h5>Outflows -  Water Flow Directionality</h5>
                <h5 className="legend-source">Source: USGS/True Elements HUC12 Outflow Vectors</h5>
                <hr />
            </div>
        )
    }

    if (input === "hucs") {
        return (
            <div className='quality-references'>
                <h5>HUCs -  Outlines of Watersheds</h5>
                <h5 className="legend-source">Source: USGS Hydrologic Unit Codes</h5>
                <hr />
            </div>
        )
    }

    if (input === "HUCsMexico") {
        return (
            <div className='quality-references'>
                <h5>HUCs - Outlines of Mexican Watersheds</h5>
                <h5 className="legend-source"><a href={'https://databasin.org/datasets/5637f67d31d94a1cb165cdbddb8c4257/'} target={'_blank'} >Conservation Biology Institute</a></h5>
                <hr />
            </div>
        )
    }

    if (input === "nhd") {
        return (
            <div className='quality-references'>
                <h5>NHD -  Network of Water Polygon Features: Streams, Lakes Rivers, Swamps, Marshes, etc.</h5>
                <h5 className="legend-source">Source: USGS National Hydrography Database</h5>
                <hr />
            </div>
        )
    }

    if (input === "pollution") {
        return (
            <div className='quality-references'>
                <h5>Toxic 100 Water Polluters layer</h5>
                <h5 className="legend-source">Source: <a href={'https://peri.umass.edu/toxic-100-water-polluters-index-current'} target={'_blank'} >Political Economy Research Institute (PERI) University of Massachusetts Amherst</a></h5>
                <hr />
            </div>
        )
    }

    if (input === "water quality") {
        return (
            <div className='quality-references'>
                <h5>True QI Range - What do these scores mean?</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' className='green' />
                            </td>
                            <td style={{ width: '50px' }}>90 - 100</td>
                            <td>No notable irregularities detected</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' className='yellow' />
                            </td>
                            <td>81 - 90</td>
                            <td>
                                Potential irregularities detected within a typical range
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' className='red' />
                            </td>
                            <td>70 - 80</td>
                            <td>Potential irregularities detected, more information available</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: True Elements AI Engine Generator</h5>
                <hr />
            </div>
        );
    }

    if (input === "drought") {
        return (
            <div className='quality-references'>
                <h5>Drought Monitoring - What do these colors scores mean?</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#f4fb18' }} />
                            </td>
                            <td> - D0 (Abnormally Dry)</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#f99010' }} />
                            </td>
                            <td> - D1 (Moderate Drought)</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ea631f' }} />
                            </td>
                            <td> - D2 (Severe Drought)</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#fa3f19' }} />
                            </td>
                            <td> - D3 (Extreme Drought)</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ab0707' }} />
                            </td>
                            <td> - D4 (Exceptional Drought)</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source"><p id="multi-line">Sources:</p> <br /> National Oceanic Atmospheric Association (NOAA) <br />
                    'United States' Department of Agriculture (USDA) <br />
                    National Integrated Drought Information System (NIDIS)</h5>
                <hr />
            </div>
        );
    }

    if (input === "aquifers") {
        return (
            <div className='quality-references'>
                <h5>Principal Aquifers - What do these colors scores mean?</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#8a658b' }} />
                            </td>
                            <td> - Carbonate-rock aquifers</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#efa3a3' }} />
                            </td>
                            <td> - Igneous and metamorphic-rock aquifers</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#f1c065' }} />
                            </td>
                            <td> - Sandstone and carbonate-rock aquifers</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#633b2c' }} />
                            </td>
                            <td> - Sandstone aquifers</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#2d2843' }} />
                            </td>
                            <td> - Semiconsolidated sand aquifers</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#292154' }} />
                            </td>
                            <td> - Unconsolidated sand and gravel aquifers</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: USGS Water Resources</h5>
                <hr />
            </div>
        );
    }

    if (input === "wells") {
        return (
            <div className='quality-references'>
                <h5>Domestic Wells by Population - People using domestic supply wells per sq kilometer</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#2c8f29' }} />
                            </td>
                            <td> {'>'} 0.5</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#4fc32c' }} />
                            </td>
                            <td> {'>'} 10</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ddeded' }} />
                            </td>
                            <td> {'>'} 30</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#98c3f0' }} />
                            </td>
                            <td> {'>'} 50</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#0b61bf' }} />
                            </td>
                            <td> {'>'} 70</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#254153' }} />
                            </td>
                            <td> {'>'} 90</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: USGS ScienceBase Catalog</h5>
                <hr />
            </div>
        );
    }

    if (input === "nitrates") {
        return (
            <div className='quality-references'>
                <h5>Nitrates - Probability of nitrate contamination exceeding 4 mg/L in predominantly shallow, recently recharged ground waters of the 'United States'.</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#1a9850' }} />
                            </td>
                            <td> {'>'} 0</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#66bd63' }} />
                            </td>
                            <td> {'>'} 0.2</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#a6d96a' }} />
                            </td>
                            <td> {'>'} 0.5</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#d9ef8b' }} />
                            </td>
                            <td> {'>'} 0.8</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffbf' }} />
                            </td>
                            <td> {'>'} 1</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#fee08b' }} />
                            </td>
                            <td> {'>'} 2</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#fdae61' }} />
                            </td>
                            <td> {'>'} 3</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#f46d43' }} />
                            </td>
                            <td> {'>'} 5</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#d73027' }} />
                            </td>
                            <td> {'>'} 12</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: USGS National Water-Quality Assessment (NAWQA) Program</h5>
                <hr />
            </div>
        );
    }

    if (input === "NOAALayer") {
        return (
            <h5 style={{ fontSize: '12px', marginLeft: "-7px" }} className="legend-source">
                Source:
                <a
                    href={'https://coast.noaa.gov/slr/#/layer/slr/0/-9158631.275009884/3203761.0386067335/11/satellite/none/0.8/2050/interHigh/midAccretion'}
                    target={'_blank'}
                >
                    NOAA Sea Level Rise and Coastal Flooding Impacts
                </a>
            </h5>
        )
    }

    if (input === "femaZones") {
        return (
            <div className='quality-references'>
                <h5>FEMA Flood Hazard Zones</h5>
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 90%, 41%, 0.7)' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Special Flood Hazard Area (SFHA): Area that will be inundated by the flood event having a 1-percent chance of being equaled or exceeded in any given year
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsl(63, 100%, 60%)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Areas between the limits of the base flood and the 0.2-percent-annual-chance (or 500-year) flood
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(123, 96%, 77%, 0.5)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Areas of minimal flood hazard: Areas outside the SFHA and higher than the elevation of the 0.2-percent-annual-chance flood
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">
                    Source:
                    <a href={'https://www.fema.gov/flood-maps/national-flood-hazard-layer'} target={'_blank'} >
                        FEMA Flood Hazard Zones
                    </a>
                </h5>
                <hr />
            </div>
        )
    }

    if (input === "aqueductAtlasCoastalFlood report") {
        return (
            <div className='quality-references'>
                <h5>Aqueduct Water Risk Atlas</h5>
                <br />
                <h5>COASTAL FLOOD RISK</h5>
                <br />
                <p style={{ fontSize: '12px', marginBottom: '150%' }}>
                    <h5>Description:</h5>
                    Coastal flood risk measures the percentage of the population expected to be affected by coastal flooding in an average year, accounting for existing flood protection standards. Flood risk is assessed using hazard (inundation caused by storm surge), exposure (population in flood zone), and vulnerability.
                </p>
                <br />
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 73%, 30%, 0.6)' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Extremely High (more than 2 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(3, 79%, 50%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                High (3 in 10,000 to 2 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(51, 89%, 51%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Medium - High (7 in 100,000 to 3 in 10,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(73, 88%, 55%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low - Medium (9 in 1,000,000 to 7 in 100,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(91, 44%, 48%, 0.3)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low (0 to 9 in 1,000,000)
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5 className="legend-source">
                    Source:
                    {' '}
                    <a href={'https://www.wri.org/publication/aqueduct-30'} target={'_blank'} >
                        Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
                        “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk Indicators.”
                        Technical Note. Washington, DC: World Resources Institute
                    </a>
                </h5>
                <hr />
            </div>
        )
    }

    if (input === "aqueductAtlasWaterStress report") {
        return (
            <div className='quality-references'>
                <h5><strong>Aqueduct Water Risk Atlas</strong></h5>
                <br />
                <h5>WATER STRESS</h5>
                <br />
                <p style={{ fontSize: '12px', marginBottom: '150%' }}>
                    <h5>Description:</h5>
                    Baseline water stress measures the ratio of total water withdrawals to available renewable surface and groundwater supplies. Water withdrawals include domestic, industrial, irrigation, and livestock consumptive and non-consumptive uses. Available renewable water supplies include the impact of upstream consumptive water users and large dams on downstream water availability. Higher values indicate more competition among users.
                </p>
                <br />
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 73%, 30%, 0.6)' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Extremely High ({'>'}80%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(3, 79%, 50%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                High (40-80%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(51, 89%, 51%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Medium - High (20-40%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(73, 88%, 55%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low - Medium (10-20%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(91, 44%, 48%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low ({'<'}10%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 4%, 53%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Arid and Low Water Use
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5 className="legend-source">
                    Source:
                    {' '}
                    <a href={'https://www.wri.org/publication/aqueduct-30'} target={'_blank'} >
                        Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
                        “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk Indicators.”
                        Technical Note. Washington, DC: World Resources Institute
                    </a>
                </h5>
                <hr />
            </div>
        )
    }

    if (input === "aqueductAtlasRiverine report") {
        return (
            <div className='quality-references'>
                <h5><strong>Aqueduct Water Risk Atlas</strong></h5>
                <br />
                <h5>RIVERINE FLOOD RISK</h5>
                <br />
                <p style={{ fontSize: '12px', marginBottom: '150%' }}>
                    <h5>Description:</h5>
                    Riverine flood risk measures the percentage of population expected to be affected by Riverine flooding in an average year, accounting for existing flood-protection standards. Flood risk is assessed using hazard (inundation caused by river overflow), exposure (population in flood zone), and vulnerability
                </p>
                <br />
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#D61F1F' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Extremely High (more than 1 in 100)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'rgb(234 120 11)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                High (6 in 1,000 to 1 in 100)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FFD301' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Medium - High (2 in 1,000 to 6 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#006B3D' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low - Medium (1 in 1,000 to 2 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#7BB662' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low (0 to 1 in 1,000)
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5 className="legend-source">
                    Source:
                    {' '}
                    <a href={'https://www.wri.org/publication/aqueduct-30'} target={'_blank'} >
                        Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
                        “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk Indicators.”
                        Technical Note. Washington, DC: World Resources Institute
                    </a>
                </h5>
                <hr />
            </div>
        )
    }


    if (input === "aqueductAtlasCoastalFlood") {
        return (
            <div className='quality-references'>
                <h5>Aqueduct Water Risk Atlas</h5>
                <br />
                <h5>COASTAL FLOOD RISK</h5>
                <br />
                <p style={{ fontSize: '12px' }}>
                    <h5>Description:</h5>
                    Coastal flood risk measures the percentage of the population expected to be affected by coastal flooding in an average year, accounting for existing flood protection standards. Flood risk is assessed using hazard (inundation caused by storm surge), exposure (population in flood zone), and vulnerability.
                </p>
                <br />
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 73%, 30%, 0.6)' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Extremely High (more than 2 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(3, 79%, 50%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                High (3 in 10,000 to 2 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(51, 89%, 51%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Medium - High (7 in 100,000 to 3 in 10,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(73, 88%, 55%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low - Medium (9 in 1,000,000 to 7 in 100,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(91, 44%, 48%, 0.3)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low (0 to 9 in 1,000,000)
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5 className="legend-source">
                    Source:
                    {' '}
                    <a href={'https://www.wri.org/publication/aqueduct-30'} target={'_blank'} >
                        Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
                        “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk Indicators.”
                        Technical Note. Washington, DC: World Resources Institute
                    </a>
                </h5>
                <hr />
            </div>
        )
    }

    if (input === "aqueductAtlasWaterStress") {
        return (
            <div className='quality-references'>
                <h5><strong>Aqueduct Water Risk Atlas</strong></h5>
                <br />
                <h5>WATER STRESS</h5>
                <br />
                <p style={{ fontSize: '12px' }}>
                    <h5>Description:</h5>
                    Baseline water stress measures the ratio of total water withdrawals to available renewable surface and groundwater supplies. Water withdrawals include domestic, industrial, irrigation, and livestock consumptive and nonconsumptive uses. Available renewable water supplies include the impact of upstream consumptive water users and large dams on downstream water availability. Higher values indicate more competition among users.
                </p>
                <br />
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 73%, 30%, 0.6)' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Extremely High ({'>'}80%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(3, 79%, 50%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                High (40-80%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(51, 89%, 51%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Medium - High (20-40%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(73, 88%, 55%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low - Medium (10-20%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(91, 44%, 48%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low ({'<'}10%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 4%, 53%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Arid and Low Water Use
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5 className="legend-source">
                    Source:
                    {' '}
                    <a href={'https://www.wri.org/publication/aqueduct-30'} target={'_blank'} >
                        Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
                        “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk Indicators.”
                        Technical Note. Washington, DC: World Resources Institute
                    </a>
                </h5>
                <hr />
            </div>
        )
    }

    if (input === "aqueductAtlasRiverine") {
        return (
            <div className='quality-references'>
                <h5><strong>Aqueduct Water Risk Atlas</strong></h5>
                <br />
                <h5>RIVERINE FLOOD RISK</h5>
                <br />
                <p style={{ fontSize: '12px' }}>
                    <h5>Description:</h5>
                    Riverine flood risk measures the percentage of population expected to be affected by Riverine flooding in an average year, accounting for existing flood-protection standards. Flood risk is assessed using hazard (inundation caused by river overflow), exposure (population in flood zone), and vulnerability
                </p>
                <br />
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#D61F1F' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Extremely High (more than 1 in 100)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'rgb(234 120 11)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                High (6 in 1,000 to 1 in 100)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FFD301' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Medium - High (2 in 1,000 to 6 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#006B3D' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low - Medium (1 in 1,000 to 2 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#7BB662' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low (0 to 1 in 1,000)
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5 className="legend-source">
                    Source:
                    {' '}
                    <a href={'https://www.wri.org/publication/aqueduct-30'} target={'_blank'} >
                        Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
                        “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk Indicators.”
                        Technical Note. Washington, DC: World Resources Institute
                    </a>
                </h5>
                <hr />
            </div>
        )
    }

    if (input === "aqueductAtlas") {
        return (
            <div className='quality-references'>
                <h5><strong>Aqueduct Water Risk Atlas</strong></h5>
                <br />
                <h5>WATER STRESS</h5>
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 73%, 30%, 0.6)' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Extremely High ({'>'}80%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(3, 79%, 50%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                High (40-80%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(51, 89%, 51%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Medium - High (20-40%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(73, 88%, 55%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low - Medium (10-20%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(91, 44%, 48%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low ({'<'}10%)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 4%, 53%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Arid and Low Water Use
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5>COASTAL FLOOD RISK</h5>
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(0, 73%, 30%, 0.6)' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Extremely High (more than 2 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(3, 79%, 50%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                High (3 in 10,000 to 2 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(51, 89%, 51%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Medium - High (7 in 100,000 to 3 in 10,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(73, 88%, 55%, 0.6)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low - Medium (9 in 1,000,000 to 7 in 100,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'hsla(91, 44%, 48%, 0.3)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low (0 to 9 in 1,000,000)
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h5>RIVERINE FLOOD RISK</h5>
                <table>
                    <tbody>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                        }}>
                            <td style={{
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#D61F1F' }} />
                            </td>
                            <td style={{
                                textAlign: 'center',
                                display: 'flex',
                                marginTop: '2px'
                            }}>
                                -
                            </td>
                            <td>
                                Extremely High (more than 1 in 100)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'rgb(234 120 11)' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                High (6 in 1,000 to 1 in 100)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FFD301' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Medium - High (2 in 1,000 to 6 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#006B3D' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low - Medium (1 in 1,000 to 2 in 1,000)
                            </td>
                        </tr>
                        <tr style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '4px'
                        }}>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#7BB662' }} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                -
                            </td>
                            <td>
                                Low (0 to 1 in 1,000)
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">
                    Source:
                    {' '}
                    <a href={'https://www.wri.org/publication/aqueduct-30'} target={'_blank'} >
                        Hofste, R., S. Kuzma, S. Walker, E.H. Sutanudjaja, et. al. 2019.
                        “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk Indicators.”
                        Technical Note. Washington, DC: World Resources Institute
                    </a>
                </h5>
                <hr />
            </div>
        )
    }


    if (input === "femaBoundaries") {
        return (
            <h5 className="legend-source">
                Source:
                <a href={'https://www.fema.gov/flood-maps/national-flood-hazard-layer'} target={'_blank'} >
                    FEMA Flood Boundaries
                </a>
            </h5>
        )
    }

    if (input === "terraclimate") {
        return (
            <div className='quality-references'>
                <h5>Microsoft Terraclimate - Annual Levels of global precipitation (inches)</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#440154' }} />
                            </td>
                            <td> - 0</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#443983' }} />
                            </td>
                            <td> - 1</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#31688e' }} />
                            </td>
                            <td> - 11</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#21918c' }} />
                            </td>
                            <td> - 46</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#35b779' }} />
                            </td>
                            <td> - 128</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#90d743' }} />
                            </td>
                            <td> - 279</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.climatologylab.org/terraclimate.html'} target={'_blank'} >Microsoft Climatology Lab</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "evapotranspiration") {
        return (
            <div className='quality-references'>
                <h5>Microsoft Terraclimate - Evapotranspiration: The sum of evaporation from the land surface plus transpiration from plants</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#1A8693' }} />
                            </td>
                            <td> - 0</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#4EA2A2' }} />
                            </td>
                            <td> - 1</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#D4B95E' }} />
                            </td>
                            <td> - 9</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#D28F34' }} />
                            </td>
                            <td> - 35</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#B34232' }} />
                            </td>
                            <td> - 90</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#741a33' }} />
                            </td>
                            <td> - 186</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://planetarycomputer.microsoft.com/dataset/terraclimate'} target={'_blank'} >Microsoft Climatology Lab</a></h5>
                <hr />
            </div>
        );
    }
    if (input === "EJDemoSelected") {
        return (
            <div className='quality-references'>
                <h5>Demographic Index (National Percentiles)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>The Demographic Index is a combination of percent low-income and percent minority. For each Census block group, these two numbers are simply averaged together. The formula is as follows: Demographic Index = (% people of color + % low-income) / 2.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#CA7676' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#E8C276' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#F5F5AF' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ABABAB' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#D0D0D0' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#e2e2e2' }} />
                            </td>
                            <td> 50 - 60 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> Less than 50 percentile</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJLowIncomeSelected") {
        return (
            <div className='quality-references'>
                <h5>Low Income Index (National Percentiles)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>Percent of individuals whose ratio of household income to poverty level in the past 12 months was less than 2 (as a fraction of individuals for whom ratio was determined).</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#003728' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#006A4E' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#2E856E' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#5CA08E' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#8ABAAE' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#B8D5CD' }} />
                            </td>
                            <td> 50 - 60 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> Less than 50 percentile</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJPOCSelected") {
        return (
            <div className='quality-references'>
                <h5>People of Color (National Percentiles)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>The percent of individuals in a block group who list their racial status as a race other than white alone and/or list their ethnicity as Hispanic or Latino. That is, all people other than non-Hispanic white-alone individuals. The word "alone" in this case indicates that the person is of a single race, not multiracial.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#332b00' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#4d4100' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#665600' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#998100' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ccac00' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffd700' }} />
                            </td>
                            <td> 50 - 60 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> Less than 50 percentile</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJUnemploymentSelected") {
        return (
            <div className='quality-references'>
                <h5>Unemployment Rate (National Percentiles)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>All those who did not have a job at all during the reporting period, made at least one specific active effort to find a job during the prior 4 weeks, and were available for work (unless temporarily ill).</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FE5A1D' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FE8116' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FFA80F' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FFCF07' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FFF600' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FCFF69' }} />
                            </td>
                            <td> 50 - 60 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> Less than 50 percentile</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJLingIsoSelected") {
        return (
            <div className='quality-references'>
                <h5>Linguistically Isolated (National Percentiles)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>Percent of households in which no one age 14 and over speaks English "very well" or speaks English only (as a fraction of households).</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#006868' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#006868' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#00a2a2' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#00dddd' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#00ffff' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#3ddbd9' }} />
                            </td>
                            <td> 50 - 60 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> Less than 50 percentile</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJLessHSSelected") {
        return (
            <div className='quality-references'>
                <h5>Less Than High School Education (National Percentiles)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>Percent of individuals age 25 and over with less than high school degree.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#2f443a' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#374f44' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#3f5b4e' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#476658' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#4f7262' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#577d6c' }} />
                            </td>
                            <td> 50 - 60 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> Less than 50 percentile</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJFiveAndUnderAgeSelected") {
        return (
            <div className='quality-references'>
                <h5>Under Age 5 (National Percentiles)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>Percent of individuals under age 5 as a fraction of population.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#D62226' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#F5C603' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#01AA31' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#1FC3CD' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#4542B9' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#411271' }} />
                            </td>
                            <td> 50 - 60 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> Less than 50 percentile</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJSixtyFourAndOverSelected") {
        return (
            <div className='quality-references'>
                <h5>Over Age 64 (National Percentiles)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>Percent of individuals over age 64 as a fraction of the population.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#A17A74' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#F4BF90' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#FCE8B8' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#C5D4AB' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#7F6890' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#577d6c' }} />
                            </td>
                            <td> 50 - 60 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> Less than 50 percentile</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJWasteWaterDischarge") {
        return (
            <div className='quality-references'>
                <h5>Wastewater Dischargers Indicator (Stream Proximity and Toxic Concentration)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>RSEI modeled Toxic Concentrations at stream segments within 500 meters, divided by distance in kilometers (km). Calculated from RSEI modeled toxic concentrations to stream reach segments.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#76520e' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#926c15' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#b69121' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#dbb42c' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#fad643' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> 50 - 60 percentile or less</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: 'black' }} />
                            </td>
                            <td>No data</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJUndergroundStorageTanks") {
        return (
            <div className='quality-references'>
                <h5>Underground Storage Tanks (UST) and Leaking UST (LUST)</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>Count of LUSTs (multiplied by a factor of 7.7) and the number of USTs within a 1,500-foot buffered block group. Calculated from EPA UST Finder.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#143d52' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#1d5672' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#256e93' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#2d87b4' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#3399cc' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> 50 - 60 percentile or less</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJRMP") {
        return (
            <div className='quality-references'>
                <h5>Risk Management Plan (RMP) Facility Proximity</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>Count of RMP (potential chemical accident management plan) facilities within 5 km (or nearest one beyond 5 km), each divided by distance in km. Calculated from EPA RMP database.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#10002b' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#3c096c' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#7b2cbf' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#c77dff' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#e0aaff' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> 50 - 60 percentile or less</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "EJSuperfund") {
        return (
            <div className='quality-references'>
                <h5>Superfund proximity</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>Count of proposed and listed NPL sites within 5 km (or nearest one beyond 5 km), each divided by distance in km. Count excludes deleted sites. Source: Calculated from EPA CERCLIS database.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#008000' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#1f991f' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#47b347' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#7acc7a' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#b8e6b8' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> 50 - 60 percentile or less</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>


        );
    }

    if (input === "EJHazardousWaste") {
        return (
            <div className='quality-references'>
                <h5>Hazardous Waste Proximity</h5>
                <p style={{ fontSize: '10px', marginBottom: '0.5rem' }}>Count of hazardous waste management facilities (TSDFs and LQGs) within 5 km (or nearest one beyond 5 km), each divided by distance in km. Calculated from EPA RCRAInfo database.</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#942a00' }} />
                            </td>
                            <td> 95 - 100 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#c93900' }} />
                            </td>
                            <td> 90 - 95 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#e44000' }} />
                            </td>
                            <td> 80 - 90 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ff4800' }} />
                            </td>
                            <td> 70 - 80 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ff5b1b' }} />
                            </td>
                            <td> 60 - 70 percentile</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#ffffff' }} />
                            </td>
                            <td> 50 - 60 percentile or less</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.epa.gov/ejscreen/ejscreen-map-descriptions#category-demographics'} target={'_blank'} >EPA Environmental Justice Screen</a></h5>
                <hr />
            </div>
        );
    }

    if (input === "Water Service Boundary") {
        return (
            <div className='quality-references'>
                <h5 style={{ fontSize: '16px' }}>Provisional Water Utility Service Area Boundaries</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#60C492' }} />
                            </td>
                            <td>Tier 1 - Georeferenced boundary sourced from state agencies and utilities</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#36A1C4' }} />
                            </td>
                            <td>Tier 2a - Matched government jurisdiction or Census Place boundary</td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faCircle} size='lg' style={{ color: '#C73234' }} />
                            </td>
                            <td>Tier 3 - Estimated location and extent</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="legend-source">Source: <a href={'https://www.hydroshare.org/resource/20b908d73a784fc1a097a3b3f2b58bfb/'} target={'_blank'} >U.S. Community Water Systems Service Boundaries</a></h5>
                <hr />
            </div>
        );
    }
}



