import React, {
  useState,
  useEffect,
  useRef,
  useContext
} from 'react';
import { RefContext } from '../context/RefContext';
import { qualityReferences } from '../components/main/legend';
import settings from '../settings.json';
import Map, { FullscreenControl, Source, Layer } from 'react-map-gl';
import agent from '../api/agent';
import DrinkingWaterQuality from '../components/DrinkingWaterQuality';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Accordion } from 'react-bootstrap'
import SideNavBar from '../components/main/SideBar';
import RenderLayers from '../components/RenderLayers/RenderLayers';

const zipcodes = require('zipcodes');

const Home = ({
  viewport,
  setViewport,
  loading,
  setLoading
}: any) => {

  console.log(viewport);


  const [nhdSelected, setNhdSelected] = useState(false);
  const [hucsSelected, setHucsSelected] = useState(false);
  const [outflowsSelected, setOutflowsSelected] = useState(false);
  const [principalAquifersSelected, setPrincipalAquifersSelected] = useState(false);
  const [domesticWellsSelected, setDomesticWellsSelected] = useState(false);
  const [zipSelected, setZipSelected] = useState(false);
  const [ohioOutflows, setOhioOutflows] = useState(true);

  const global = {
    nhdSelected, setNhdSelected,
    hucsSelected, setHucsSelected,
    outflowsSelected, setOutflowsSelected,
    principalAquifersSelected, setPrincipalAquifersSelected,
    domesticWellsSelected, setDomesticWellsSelected,
    zipSelected, setZipSelected,
    ohioOutflows, setOhioOutflows
  }

  const mapRef = useRef<any>()

  const {
    setCurrentRef,
    setCurrentReport,
  } = useContext<any>(RefContext)

  const report = {
    reportName: 'Drinking Water Quality True QI Scores',
    parentReport: 'Home'
  }

  const goToSite = () => {
    setTimeout(() => {
      return mapRef.current.flyTo({
        center: [-80.62503529003905, 40.74030507726735],
        zoom: 7
      })
    }, 2000)
  }

  useEffect(() => {
    goToSite()
  }, [])

  useEffect(() => {
    setCurrentReport(report)
  }, [])


  const bounds = mapRef.current
    ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null;

  useEffect(() => setCurrentRef(mapRef), [mapRef])

  const noLayerStyle = "mapbox://styles/trueelementsmapping/cle7ge23j000501nt0u0wjnk5"
  const defaultMapStyle = "mapbox://styles/trueelementsmapping/cl308g1kz000g14mukvs6wiye"
  

  return (
    <>
      <div className="map-wrapper">
        <SideNavBar global={global} />
        <Map
          ref={mapRef}
          style={{ position: 'absolute', top: '0', height: '100vh', marginLeft: '5%' }}
          longitude={viewport.longitude}
          latitude={viewport.latitude}
          zoom={viewport.zoom}
          mapboxAccessToken={settings.maboxKey}
          initialViewState={viewport}
          projection={'mercator'}
          onMove={(e) => {
            setViewport({
              longitude: e.viewState.longitude,
              latitude: e.viewState.latitude,
              zoom: e.viewState.zoom,
            })
          }
          }
          mapStyle={ohioOutflows ? defaultMapStyle : noLayerStyle}
        >
          <RenderLayers global={global} mapRef={mapRef} />
        </Map>
        <>
          {
            // @ts-ignore
            nhdSelected
            // @ts-ignore
            | hucsSelected
            // @ts-ignore
            | outflowsSelected
            // @ts-ignore
            | domesticWellsSelected
            // @ts-ignore
            | principalAquifersSelected && (
              <div className="map-legend">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Legend</Accordion.Header>
                    <Accordion.Body>
                      {nhdSelected ? qualityReferences("nhd") : ''}
                      {hucsSelected ? qualityReferences("hucs") : ''}
                      {outflowsSelected ? qualityReferences("outflows") : ''}
                      {principalAquifersSelected ? qualityReferences("aquifers") : ''}
                      {domesticWellsSelected ? qualityReferences("wells") : ''}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )
          }
        </>



        <div className="copyright">
          <a
            href="https://trueelements.com/"
            target="_blank"
          >
            Powered by True Elements, Inc. Copyright 2023
          </a>
        </div>
      </div>
    </>
  )
}

export default Home