import {
    Layer,
    Source,
    FillLayer,
  } from "react-map-gl";
import settings from "../../settings.json";
import { useState } from 'react';


const outflowsFillLayer: FillLayer = {
  id: "outflowslayer",
  type: "fill",
  "source-layer": settings.tileSetURLs.outflows_sourcelayer,
  paint: {
    "fill-opacity": .5,
    "fill-outline-color": "#ffd500",
    'fill-color': '#ffd500',
  },
};

const OutflowLayer = (props: any) => {
    return (
        <>
                <Source
                id="outflows-source"
                type="vector"
                url={settings.tileSetURLs.outflows}
                  >
                  <Layer beforeId={'aerialway'}
                    {...outflowsFillLayer}                
                  />
                </Source>
        </>
    )
}

export default OutflowLayer;    


