import React, { useState, useEffect } from 'react';
import NavigationBar from './main/NavigationBar';
import Home from '../pages/Home';
import settings from '../settings.json';
import AppContextProvider from '../context/AppContext';
import RefContextProvider from '../context/RefContext';
import PointContextProvider from '../context/PointContext';
import Breadcrumb from './main/Breadcrumb';
import DrinkingWaterTrueQIReport from '../reports/DrinkingWaterTrueQI/DrinkingWaterTrueQIReport';
import Spinner from './main/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import {
  getDefaultsFromLocalStorage,
  getDefaultHuc8,
  getDefaultLocation,
  LOCALSTORAGE_DEFAULTS_KEY
} from '../utils'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

import '../assets/scss/master.scss'
import 'react-toastify/dist/ReactToastify.css';

const App = () => {

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drinkingWaterQualitySelected, setDrinkingWaterQualitySelected] = useState(false)
  const [currentRef, setCurrentRef] = useState({})

  const defaultLocalStorageObject = getDefaultsFromLocalStorage();

  const [selectedHUC8, setSelectedHUC8] = useState(getDefaultHuc8(defaultLocalStorageObject));
  const [currentLocation, setCurrentLocation] = useState(getDefaultLocation(defaultLocalStorageObject));
  const [trackerOn, setTrackerOn] = useState(false)
  const [currentReport, setCurrentReport] = useState()
  const [loading, setLoading] = useState(false)
  const [currentPoint, setCurrentPoint] = useState<object>({})

  const [viewport, setViewport] = useState<any>({
    longitude: settings.defaultLocationMap.lng,
    latitude: settings.defaultLocationMap.lat,
    zoom: settings.defaultLocationMap.zoom,
  });

  const notify = (input: string, condition = null) => {
    if (condition === null) {
      toast(input);
    }

    if (condition === 'warn') {
      toast.warn(input)
    }

    if (condition === 'error') {
      toast.error(input)
    }

    if (condition === 'info') {
      toast.info(input)
    }

    if (condition === 'success') {
      toast.success(input)
    }

  };

  const pointContextValue = {
    currentPoint: currentPoint,
    setCurrentPoint: (input: object) => {
      setCurrentPoint(input);
    },
  };

  const RefContextValue = {
    currentRef: currentRef,
    setCurrentRef: (input: object) => {
      setCurrentRef(input);
    },
    currentReport: currentReport,
    setCurrentReport: (input: any) => {
      setCurrentReport(input)
    },
  }

  const contextValue = {
    selectedHUC8: selectedHUC8,
    setHUC8: (huc8: string) => {
      setSelectedHUC8(huc8);
    },
    currentLocation: currentLocation,
    setCurrentLocation: (location: string) => {
      setCurrentLocation(location);
    },
    updateContext: (huc8: string, location: string) => {
      setCurrentLocation(location);
      setSelectedHUC8(huc8);
      localStorage.setItem(LOCALSTORAGE_DEFAULTS_KEY, JSON.stringify({ location: location, huc8: huc8 }));
    },
  };

  return (
    <>
      <PointContextProvider value={pointContextValue}>
        <RefContextProvider value={RefContextValue}>
          <AppContextProvider value={contextValue}>
            <BrowserRouter>
              <NavigationBar
                viewport={viewport}
                setViewport={setViewport}
              />
              {/* {currentReport && <Breadcrumb
                viewport={viewport}
                setViewport={setViewport}
                trackerOn={trackerOn}
                setTrackerOn={setTrackerOn}
              />} */}
              <Routes>
                <Route path='/' element={
                  <Home
                    viewport={viewport}
                    setViewport={setViewport}
                    setLoading={setLoading}
                    loading={loading}
                  />
                }
                />
                <Route
                  path='/DrinkingWaterTrueQIReport'
                  element={
                    <DrinkingWaterTrueQIReport
                      loading={loading}
                      setLoading={setLoading}
                      viewport={viewport}
                      notify={notify}
                      setViewport={setViewport}
                    />
                  }
                />
              </Routes>
            </BrowserRouter>
          </AppContextProvider>
        </RefContextProvider>
      </PointContextProvider>
      <Spinner active={loading} />
      <ToastContainer />
    </>
  )
}

export default App